// extracted by mini-css-extract-plugin
export var Article = "style-module--Article--2f61f";
export var BackArrow = "style-module--BackArrow--de935";
export var Banner = "style-module--Banner--8696e";
export var BannerCaption = "style-module--BannerCaption--0a3e0";
export var BannerImage = "style-module--BannerImage--cc4da";
export var Body = "style-module--Body--45d11";
export var Breadcrumb = "style-module--Breadcrumb--9a493";
export var Category = "style-module--Category--cd0b6";
export var Content = "style-module--Content--555f5";
export var Details = "style-module--Details--5cb94";
export var Footer = "style-module--Footer--07cd7";
export var Header = "style-module--Header--f58b3";
export var Keyword = "style-module--Keyword--3887c";
export var ReadingTime = "style-module--ReadingTime--72822";